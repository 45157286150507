<script setup>
const props = defineProps({
  loaderSize: {
    type: Number,
    default: 50,
  },
  color: {
    type: String,
    default: "#000",
  },
});

const loaderSizePx = computed(() => props.loaderSize + "px");
const ringSizePx = computed(() => props.loaderSize - 8 + "px");
</script>
<template>
  <div
    class="v-loader-ring"
    :style="{
      width: loaderSize + 'px',
      height: loaderSize + 'px',
    }"
  >
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<style scoped>
.v-loader-ring {
}

[absolute] {
  top: 50%;
  left: 50%;
  position: absolute;
  width: 0px;
  height: 0px;
}

[fixed] {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.lds-ring {
  /* display: inline-block; */
  position: relative;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: v-bind(ringSizePx);
  height: v-bind(ringSizePx);
  margin: 4px;
  border: 2px solid v-bind("props.color");
  border-radius: 50%;
  animation: lds-ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: v-bind("props.color") transparent transparent transparent;
  /* border-color: var(--text-color) ; */
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.2s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.1s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.05s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
